import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import { Wrapper, LinkWrapper } from './styles';
interface IPagination {
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    humanPageNumber: number;
    numberOfPages: number;
  };
}

const Pagination = ({ pageContext }: IPagination): ReactElement => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <Wrapper>
      <LinkWrapper>
        {previousPagePath && (
          <Link to={previousPagePath} rel="prev">
            Previous
          </Link>
        )}
      </LinkWrapper>
      {/* {numberOfPages > 1 && (
        <div className="pagination-location">
          Page {humanPageNumber} of {numberOfPages}
        </div>
      )} */}
      <LinkWrapper>
        {nextPagePath && (
          <Link to={nextPagePath} rel="next">
            Next
          </Link>
        )}
      </LinkWrapper>
    </Wrapper>
  );
};

export default Pagination;

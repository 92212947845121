import React, { ReactElement, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { IPost, ITag } from 'src/utils/ts/blog';
import Pagination from 'src/components/BlogContent/Pagination';
import PostCard from 'src/components/BlogContent/PostCard';
import Filter from 'src/components/BlogContent/Filter';

interface IBlogContent {
  posts: {
    node: IPost;
  }[];
  categories: {
    node: ITag;
  }[];
  tags: {
    node: ITag;
  }[];
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    humanPageNumber: number;
    numberOfPages: number;
  };
}

const BlogContent = ({
  posts,
  categories,
  tags,
  pageContext,
}: IBlogContent): ReactElement => {
  const [category, setCategory] = useState('All Categories');
  const [tagFilter, setTagFilter] = useState('all');

  const handleCategoryChange = (eventKey: string) => {
    setCategory(eventKey.substring(1));
  };

  const handleTagFilterChange = (tag: string) => {
    setTagFilter(tag);
  };

  return (
    <Container>
      <Filter
        categories={categories}
        tags={tags}
        categoryFilter={category}
        handleCategoryChange={handleCategoryChange}
        handleTagFilterChange={handleTagFilterChange}
        tagFilter={tagFilter}
      />
      <Row noGutters>
        {posts
          .filter(({ node }) =>
            node.tags.find((item) => {
              if (category === 'All Categories') return true;
              return item.name === `#${category}`;
            }),
          )
          .filter(({ node }) =>
            node.tags.find((item) => {
              if (tagFilter === 'all') return true;
              return item.slug === `${tagFilter}`;
            }),
          )
          .map(({ node }: { node: IPost }) => (
            <PostCard key={node.id} post={node} />
          ))}
      </Row>
      <Pagination pageContext={pageContext} />
    </Container>
  );
};

export default BlogContent;

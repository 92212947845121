import styled from '@emotion/styled';
import Container from 'react-bootstrap/Container';

export const Wrapper = styled(Container)`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
`;

export const LinkWrapper = styled.div`
  margin: 1rem;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.link};
    font-size: ${({ theme }) => theme.fontSize.xlarge};
    :hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.link};
    }
  }
`;

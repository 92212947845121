import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { IPost, ITag } from 'src/utils/ts/blog';
import Layout from 'src/components/common/Layout';
import { MetaData } from 'src/components/common/Meta';
import BlogContent from 'src/components/BlogContent';

interface IBlog {
  data: {
    allGhostPost: {
      edges: {
        node: IPost;
      }[];
    };
    allInternalTags: {
      edges: {
        node: ITag;
      }[];
    };
    allPublicTags: {
      edges: {
        node: ITag;
      }[];
    };
  };
  location: {
    pathname: string;
  };
  pageContext: {
    previousPagePath: string;
    nextPagePath: string;
    humanPageNumber: number;
    numberOfPages: number;
  };
}

const Blog = ({
  data,
  location,
  pageContext,
}: IBlog): ReactElement => {
  const posts = data.allGhostPost.edges;
  const categories = data.allInternalTags.edges;
  const tags = data.allPublicTags.edges;
  return (
    <>
      <MetaData location={location} />
      <Layout isHome={false}>
        <BlogContent
          posts={posts}
          pageContext={pageContext}
          tags={tags}
          categories={categories}
        />
      </Layout>
    </>
  );
};

export default Blog;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allInternalTags: allGhostTag(
      filter: { visibility: { eq: "internal" } }
    ) {
      edges {
        node {
          ...GhostTagFields
        }
      }
    }
    allPublicTags: allGhostTag(
      filter: { visibility: { eq: "public" } }
    ) {
      edges {
        node {
          ...GhostTagFields
        }
      }
    }
  }
`;

import React, { ReactElement } from 'react';
import Col from 'react-bootstrap/Col';
import { ITag } from 'src/utils/ts/blog';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  Container,
  Categories,
  Select,
  Menu,
  ListItem,
} from './styles';
interface IFilter {
  categories: {
    node: ITag;
  }[];
  tags: {
    node: ITag;
  }[];
  categoryFilter: string;
  tagFilter: string;
  handleCategoryChange: (eventKey: string) => void;
  handleTagFilterChange: (tag: string) => void;
}

const Filter = ({
  categories,
  tags,
  categoryFilter,
  tagFilter,
  handleCategoryChange,
  handleTagFilterChange,
}: IFilter): ReactElement => {
  return (
    <Container noGutters>
      <Col lg="12" style={{ display: 'flex', alignItems: 'center' }}>
        <Categories onSelect={handleCategoryChange}>
          <Select variant="primary" id="dropdown-basic">
            {categoryFilter}
          </Select>
          <Menu>
            <Dropdown.Item eventKey="#All Categories">
              All Categories
            </Dropdown.Item>
            {categories.map(({ node }, i) => (
              <Dropdown.Item key={i} eventKey={node.name}>
                {node.name.substring(1)}
              </Dropdown.Item>
            ))}
          </Menu>
        </Categories>
        {tags.map(
          ({ node }, i) =>
            ['trends', 'popular'].includes(node.slug) && (
              <ListItem
                key={i}
                active={tagFilter === node.slug}
                onClick={() => handleTagFilterChange(node.slug)}
              >
                <span>{node.name}</span>
              </ListItem>
            ),
        )}
        <ListItem
          active={tagFilter === 'all'}
          onClick={() => handleTagFilterChange('all')}
        >
          <span>Most Recent</span>
        </ListItem>
      </Col>
    </Container>
  );
};

export default Filter;

import styled from '@emotion/styled';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import { AnchorHTMLAttributes } from 'react';

const borderColor = '#f7f7f7';

export const Container = styled(Row)`
  margin: 0.5rem 0 5rem 0;
  border-bottom: 1px solid ${borderColor};
`;

export const Categories = styled(Dropdown)`
  border-left: 1px solid ${borderColor};
  border-right: 1px solid ${borderColor};
  padding: 1rem;
`;

interface ActiveLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  active: boolean;
}

export const ListItem = styled.a<ActiveLink>`
  cursor: pointer;
  border-right: 1px solid ${borderColor};
  padding: 1rem 2rem;
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  border-bottom: ${(props) =>
    props.active ? `2px solid ${props.theme.colors.blue}` : ''};
`;

export const Select = styled(Dropdown.Toggle)`
  padding-left: 1rem;
  border-radius: 2px;
  width: 165px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue};
  ::after {
    margin-left: 1.255em;
  }
  :hover,
  :focus,
  :active {
    background-color: #113285 !important;
  }
`;

export const Menu = styled(Dropdown.Menu)`
  transform: translate(1rem, 4rem) !important;
`;
